import React, {useState, useCallback, useEffect, useMemo} from 'react';
import { format } from 'date-fns'
import ru from 'date-fns/esm/locale/ru'

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";

import {useHttp} from "../../hooks/http.hook";

import GetCity, {PlaceType} from "./GetCity";

import {TClientInfoStorage, TStorage} from "../../pages/MainPage";
import {changeClientT} from "./ShortClientInfo";
import {ENUM_SEX, LANG, SEX} from "../../consts/clientInfoConst";

type ClientInfoProps = {
  getClientInfo: (data: TClientInfoStorage) => void,
  clientFormType?: changeClientT,
}

const ClientForm: React.FC<ClientInfoProps> = ({ getClientInfo, clientFormType } ) => {
  const [clientId, setClientId] = useState('');
  const [clients, setClients] = useState<TStorage>({});

  const [fields, setFields] = useState<TClientInfoStorage>({
    _id: '',
    latitude: 0,
    longitude: 0,
    sunrise: "",
    sunset: "",
    birthTown: "",
    first_name: "",
    last_name: "",
    surname: "",
    first_name_changed: "",
    last_name_changed: "",
    surname_changed: "",
    birthday: "",
    weekDay: "",
    weekDayNum: "",
    lang: "RU",
    dateConsultation: "",
    sex: ENUM_SEX.MAN
  });

  const { request } = useHttp();

  useEffect(() => {
    const storageInfo: TStorage = JSON.parse(localStorage.getItem('clients') as string);
    setClients(storageInfo);

    if (clientFormType === 'edit' && storageInfo) {
      const currentClient = Object.values(storageInfo)[0];
      setFields(currentClient);
      setClientId(currentClient._id)
    }
  }, [clientFormType]);

  useEffect(() => {
    if (!!fields.birthday && !!fields.latitude && !!fields.longitude) {
      const getSunTimes = async (lat: number, lng: number) => {
        const timeZonesData = await request(`https://maps.googleapis.com/maps/api/timezone/json?location=${lat}%2C${lng}&timestamp=${new Date(fields.birthday).getTime()/1000}&key=AIzaSyC_J0JNwUqJ3VFn38WnAqG63O3WVWN8xvc`);
        const { timeZoneId } = timeZonesData;

        // const getSunriseSunSet = await request(`https://api.sunrise-sunset.org/json?lat=${lat}&lng=${lng}&date=${format(fields?.birthday, 'yyyy-MM-dd')}&formatted=0`);
        const getSunriseSunSet = await request(`https://api.sunrisesunset.io/json?lat=${lat}&lng=${lng}&timezone=${timeZoneId}&date=${format(new Date(fields.birthday), 'yyyy-MM-dd')}`)
        const { sunrise, sunset } = getSunriseSunSet.results
        setFields((prevState) => ({
          ...prevState,
          sunrise,
          sunset,
        }))
      }

      getSunTimes(fields.latitude, fields.longitude).catch((err) => {console.log(err)});
    }

  }, [fields.birthday, fields.latitude, fields.longitude, request])

  const handleChangeField = useCallback((event: any) => {
    const { name, value } = event.target;
    let newValue: { [x: string]: string | number | Date; };

    if (name === 'birthday') {
      const date = new Date(value);
      newValue = {
        birthday: value,
        weekDay: format(date, 'EEEE', {locale: ru}),
        weekDayNum: format(date, 'i', {locale: ru}),
      }
    } else if ( name === 'first_name' || name === 'last_name' || name === 'surname') {
      newValue = {
        [name]: value,
        [`${name}_changed`]: value,
      }
    } else {
      newValue = {
        [name]: value,
      }
    }

    setFields((prevState) => ({
      ...prevState,
      ...newValue,
    }))
  }, []);

  const handleCoordinate = (data: PlaceType | null) => {
    setFields((prevState) => ({
      ...prevState,
      birthTown: data?.address as string,
      latitude: data?.latitude as unknown as number,
      longitude: data?.longitude as unknown as number,
    }))
  };

  const handleChangeClient = (event: SelectChangeEvent) => {
    setClientId(event.target.value as string);
    setFields(clients[event.target.value as string]);
  };

  const handleSaveClient = useCallback((id?: string) => () => {
    const prevClients = JSON.parse(localStorage.getItem('clients') as string) || {};
    const newClientId = `id_${(+new Date()).toString()}`;

    if (id) {
      localStorage.setItem('clients', JSON.stringify({...prevClients, [id]: fields}))
    } else {
      const fieldsWithId = {...fields, _id: newClientId};
      localStorage.setItem('clients', JSON.stringify({[newClientId]: fieldsWithId, ...prevClients}))
    }
    getClientInfo(fields);
  }, [fields, getClientInfo]);

  const isDisabledBtn = useMemo(() => {
    for (const [key, value] of Object.entries(fields)) {
      if (key !== '_id' && !value) {
        return true
      }
    }
    return false
  }, [fields]);

  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      {clients && (
        <Grid container spacing={1} mb={3}>
          <Grid item xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel id="select-client-label">Выбрать клиента</InputLabel>
              <Select
                labelId="select-client-label"
                id="simple-select"
                value={clientId}
                label="Выбрать клиента"
                onChange={handleChangeClient}
                fullWidth
              >
                {Object.values(clients).map((client) => (
                  <MenuItem key={client._id} value={client._id}>{client.last_name_changed} {client.first_name_changed} {client.surname_changed}</MenuItem>)
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1} mb={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            При рождении
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="last_name"
            onChange={handleChangeField}
            fullWidth
            label="Фамилия"
            variant="outlined"
            value={fields.last_name}
            type="text"
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="first_name"
            onChange={handleChangeField}
            fullWidth
            label="Имя"
            variant="outlined"
            value={fields.first_name}
            type="text"
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="surname"
            onChange={handleChangeField}
            fullWidth
            label="Отчество"
            variant="outlined"
            value={fields.surname}
            type="text"
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} mb={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            После изменения
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="last_name_changed"
            onChange={handleChangeField}
            fullWidth
            label="Фамилия"
            variant="outlined"
            value={fields.last_name_changed}
            type="text"
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="first_name_changed"
            onChange={handleChangeField}
            fullWidth
            label="Имя"
            variant="outlined"
            value={fields.first_name_changed}
            type="text"
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="surname_changed"
            onChange={handleChangeField}
            fullWidth
            label="Отчество"
            variant="outlined"
            value={fields.surname_changed}
            type="text"
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} mb={3}>
        <Grid item xs={12} md={4}>
          <TextField
            name="birthday"
            onChange={handleChangeField}
            fullWidth
            label="Дата и время рождения"
            variant="outlined"
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            value={fields.birthday}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            name="weekDay"
            fullWidth
            label="День недели"
            variant="outlined"
            disabled
            value={fields.weekDay}
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            name="weekDayNum"
            fullWidth
            label="День недели число"
            variant="outlined"
            disabled
            value={fields.weekDayNum}
            size="small"
          />
        </Grid>

      </Grid>
      <Grid container spacing={1} mb={3}>
        <Grid item xs={12} md={4}>
          <GetCity onGetCoordinate={handleCoordinate} selectedCity={fields?.birthTown} />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            name="sunrise"
            fullWidth
            label="Время рассвета"
            variant="outlined"
            value={fields.sunrise}
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            name="sunset"
            fullWidth
            label="Время заката"
            variant="outlined"
            value={fields.sunset}
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} mb={3}>
        <Grid item xs={12} md={2}>
          <FormControl size="small" fullWidth>
            <InputLabel id="sex-select-label">Пол</InputLabel>
            <Select
              labelId="sex-select-label"
              id="sex-select"
              value={fields.sex}
              name="sex"
              label="Пол"
              onChange={handleChangeField}
              fullWidth
            >
              {SEX.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl size="small" fullWidth>
            <InputLabel id="lang-select-label">Язык</InputLabel>
            <Select
              labelId="lang-select-label"
              id="lang-select"
              value={fields.lang}
              name="lang"
              onChange={handleChangeField}
              label="Язык"
            >
              {LANG.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="dateConsultation"
            onChange={handleChangeField}
            fullWidth
            label="Дата консультации"
            variant="outlined"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            value={fields.dateConsultation}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} mb={3}>
        <Grid item xs={12}>
          <Stack spacing={2} direction="row">
            <Button
              variant="outlined"
              onClick={handleSaveClient()}
              disabled={isDisabledBtn}
            >
              Сохранить
            </Button>
            {clientId && (
              <Button
                variant="outlined"
                onClick={handleSaveClient(clientId)}
                disabled={isDisabledBtn}
              >
                Обновить
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ClientForm;
