import React, {useContext} from 'react';

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import {ClientContext} from "../../hooks/clientContext";
import BaseInfoTable from "./components/BaseInfoTable";
import PlansExpressionTable from "./components/PlansExpressionTable";
import EnergyNameTable from "./components/EnergyNameTable";
import DynamicHoroscope from "./components/DynamicHoroscope";
import BiorhythmsShort from "./components/BiorhythmsShort";
import VarnaTable from "./components/VarnaTable";
import TrigonsTable from "./components/TrigonsTable";
import PredictVolume from "./components/PredictVolume";
import IntenseTable from "./components/IntenseTable";

const TotalPage = () => {
  const mainInfo = useContext(ClientContext)
  // console.log('==========', mainInfo)
  return (
    <Grid container spacing={2} mb={3}>
      <Grid item xs={12} md={3}>
        <Paper sx={{ p: 2 }} >
          <BaseInfoTable />
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper sx={{ p: 2, mb: 2 }} >
          <PlansExpressionTable />
        </Paper>
        <Paper sx={{ p: 2, mb: 2 }} >
          <VarnaTable />
        </Paper>
        <Paper sx={{ p: 2, mb: 2 }} >
          <TrigonsTable />
        </Paper>
      </Grid>
      <Grid item xs={12} md={2}>
        <Paper sx={{ p: 2 }} >
          <EnergyNameTable />
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper sx={{ p: 2 }} >
          <BiorhythmsShort />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2 }} >
          <DynamicHoroscope />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2, mb: 2 }} >
          <PredictVolume />
        </Paper>
        <Paper sx={{ p: 2 }} >
          <IntenseTable />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default TotalPage;
