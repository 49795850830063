import React, {useState, useEffect, useCallback} from 'react';
import { Outlet } from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import TemplatePage from "./TemplatePage";
import ClientForm from "../components/ClientForm/ClientForm";
import ShortClientInfo, {changeClientT} from "../components/ClientForm/ShortClientInfo";
import {ClientContext, convertForContext, mainInfoDefaultState, TMainInfo} from '../hooks/clientContext';

export type TClientInfoStorage = {
  _id: string,
  first_name: string,
  last_name: string,
  surname: string,
  first_name_changed: string,
  last_name_changed: string,
  surname_changed: string,
  birthday: string,
  weekDay: string,
  weekDayNum: string,
  birthTown: string,
  lang: string,
  sex: string,
  sunrise: string,
  sunset: string,
  latitude: number,
  longitude: number,
  dateConsultation: string,
};
export type TStorage = {
  [key: string]: TClientInfoStorage
}

export default function MainPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [mainInfo, setMainInfo] = useState<TMainInfo>(mainInfoDefaultState);
  const [clientFormType, setClientFormType] = useState<changeClientT>();

  const getClientInfo = (data: TClientInfoStorage) => {
    setMainInfo(convertForContext(data));
    setShowForm(false);
    setShowInfo(true);
  };

  const handleChangeClient = useCallback((type: changeClientT) => {
    setClientFormType(type);
    setShowInfo(false);
    setShowForm(true);
  }, []);

  useEffect(() => {
    const storageInfo: TStorage = JSON.parse(localStorage.getItem('clients') as string);

    if (storageInfo) {
      setMainInfo(convertForContext(Object.values(storageInfo)[0]));
      setShowInfo(true);
    } else {
      setShowForm(true);
    }

    setLoading(false);
  }, []);

  return (
    <TemplatePage>
      <>
        <Grid item xs={12} mb={2}>
          {showInfo && mainInfo && <ShortClientInfo mainInfo={mainInfo} onChangeClient={handleChangeClient} />}
          {showForm && <ClientForm getClientInfo={getClientInfo} clientFormType={clientFormType} />}
        </Grid>
        <Grid item xs={12}>

          {loading && (
            <Box sx={{ width: '100%' }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
          )}
          {showInfo && mainInfo && (
            <ClientContext.Provider value={mainInfo}>
              <Outlet />
            </ClientContext.Provider>
          )}

        </Grid>
      </>
    </TemplatePage>
  );
}
