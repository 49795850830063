import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {drawerWidth} from "./Navigation/Navigation";
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";
import {useContext} from "react";
import Box from "@mui/material/Box";
import AccountMenu from "./AccountMenu";
import {AuthContext} from "../hooks/AuthContext";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

type HeaderProps = {
  open: boolean,
  handleDrawerOpen: () => void,
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = ({ open, handleDrawerOpen }: HeaderProps) => {
  const { userEmail } = useContext(AuthContext);
  return (
    <AppBar position="fixed" open={open}>
      <Toolbar
        sx={{
          justifyContent: 'space-between'
        }}
      >
        <Box display='flex' alignItems='center'>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Crystal
          </Typography>
        </Box>

        {!!userEmail.length && <AccountMenu /> }
      </Toolbar>
    </AppBar>
  )
}

export default Header;
