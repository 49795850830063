import React from 'react';
import {format, differenceInYears} from "date-fns";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import {TMainInfo} from "../../hooks/clientContext";

export type changeClientT = 'edit' | 'new';

type ShortClientInfoProps = {
  mainInfo: TMainInfo,
  onChangeClient: (type: changeClientT) => void
}
const ShortClientInfo: React.FC<ShortClientInfoProps> = ({ mainInfo, onChangeClient }) => {
  if (!mainInfo.first_name.length) {
    return <div>'Loading...'</div>
  }

  const handleChangeClient = (type: changeClientT) => () => {
    onChangeClient(type)
  }

  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Stack spacing={1} mb={1}>
        <Box mb={2}>
          <Typography variant="body1">
            {mainInfo.last_name_changed} {mainInfo.first_name_changed} {mainInfo.surname_changed}
            {' '}
            ({mainInfo.last_name} {mainInfo.first_name} {mainInfo.surname}),
            возраст: {differenceInYears(new Date(mainInfo.dateConsultation), new Date(mainInfo.birthday))}
          </Typography>
          <Typography variant="body1">
            <b>Город рождения:</b> {mainInfo.birthTown}
          </Typography>
          <Typography variant="body1">
            <b>День рождения:</b> {format(new Date(mainInfo.birthday), 'dd-MM-yyyy, HH:mm')}, {mainInfo.weekDay}
          </Typography>

          <Typography variant="body1">
            <b>Дата консультации:</b> {format(new Date(mainInfo.dateConsultation), 'dd-MM-yyyy')}
          </Typography>
        </Box>

        <Stack spacing={2} direction="row">
          <Button
            variant="outlined"
            onClick={handleChangeClient('edit')}
          >
            Редактировать
          </Button>
          <Button
            variant="outlined"
            onClick={handleChangeClient('new')}
          >
            Новый клиент
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
}

export default ShortClientInfo;
