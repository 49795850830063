import { useCallback, useState } from 'react';

type initExtendedT = {
  method?: string
  body?: any,
  headers?: any,
  params?: any
}
export const useHttp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = useCallback(async (url: string, initExtended:initExtendedT = {
    method: 'GET', body: null, headers: {}, params: null,
  }) => {
    let { body } = initExtended;
    const { headers = {}, params, } = initExtended;
    let queryString = '';
    setLoading(true);
    try {
      if (body) {
        body = JSON.stringify(body);
        headers['Content-Type'] = 'application/json';
      }

      if (params) {
        queryString = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
      }

      const response = await fetch(params ? `${url}?${queryString}` : url, { ...initExtended, body, headers });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Something went wrong');
      }

      return data;
    } catch (err) {
      // @ts-ignore
      setError(err?.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const clearError = () => setError(null);

  return {
    loading, request, error, clearError,
  };
};
