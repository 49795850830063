import React, { useState } from 'react';
import {format} from 'date-fns'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import TableSortLabel from '@mui/material/TableSortLabel';

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';

import DeleteIcon from '@mui/icons-material/Delete';
import {Modal} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

type User = {
  id: string,
  admin: boolean,
  creator: string,
  date: string,
  email: string
}
type UsersListT = {
  users: User[],
  onDeleteUser: (id: string) => void,
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const UsersList:React.FC<UsersListT> = ({ users, onDeleteUser }) => {
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDeleteUser = () => {
    onDeleteUser(selectedId);
    handleClose();
  };

  const handleOpenModal = (id: string) => () => {
    setSelectedId(id);
    handleOpen();
  }

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{maxWidth: '100%'}}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Создал</TableCell>
                <TableCell>Дата создания</TableCell>
                <TableCell>Админ</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.email}
                  </TableCell>
                  <TableCell>{row.creator}</TableCell>
                  <TableCell>{format(new Date(row.date), 'dd-MM-yyyy')}</TableCell>
                  <TableCell>{row.admin ? 'да' : 'нет'}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={handleOpenModal(row.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Удалить пользователя
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Вы действительно хотите удалить пользователя {users.find((item) => item.id === selectedId)?.email}
          </Typography>
          <br />

          <Button
            variant="outlined"
            size="small"
            color="primary"
            sx={{ mr: 3 }}
            onClick={handleClose}
          >
            Отменить
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={handleDeleteUser}
          >
            Подтвердить
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default UsersList;
