import React from "react";
import {Route, Routes} from "react-router-dom";
import MainPage from "./pages/MainPage";
import {NAV} from "./consts/navigation";
import NightPage from "./pages/NightPage";
import TotalPage from "./pages/TotalPage/TotalPage";
import BiorhythmsPage from "./pages/BiorhythmsPage/BiorhythmsPage";
import SignIn from "./pages/SignIn/SignIn";
import ProtectedRoute from "./ProtectedRouter";
import AccountPage from "./pages/AccountPage/AccountPage";
import RemindPassword from "./pages/ForgotPassword/ForgotPassword";

const routes = () => (
  <Routes>
    <Route path={NAV.SIGN_IN} element={<SignIn />} />
    <Route path={NAV.REMIND} element={<RemindPassword />} />

    <Route path="/" element={<ProtectedRoute><MainPage /></ProtectedRoute>}>
      <Route index element={<TotalPage />} />
      <Route path={NAV.TOTAL} element={<TotalPage />} />
      <Route path={NAV.BIO} element={<BiorhythmsPage />} />
      <Route path={NAV.NIGHT} element={<NightPage />} />

      <Route path="*" element={<p>There's nothing here: 404!</p>} />
    </Route>
    <Route path={NAV.ACCOUNT} element={<ProtectedRoute><AccountPage /></ProtectedRoute>} />

  </Routes>
)

export default routes();
