import React, {useContext} from "react";
import { Navigate, useLocation } from 'react-router-dom';

import {NAV} from "./consts/navigation";
import {AuthContext} from "./hooks/AuthContext";

// @ts-ignore
const ProtectedRoute = ({ children }) => {
  const { token } = useContext(AuthContext);
  const location = useLocation();

  if (!token) {
    return <Navigate to={NAV.SIGN_IN} replace state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;
