import React, { useContext } from 'react';
import {ClientContext} from "../hooks/clientContext";

const NightPage = () => {
  const mainInfo = useContext(ClientContext)
  console.log('==========', mainInfo)
  return (
    <div>расчет по ночи</div>
  );
}

export default NightPage;
