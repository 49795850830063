import {createContext} from "react";
import {TClientInfoStorage} from "../pages/MainPage";

export type TMainInfo = Omit<TClientInfoStorage, "latitude" | "longitude">;

export const convertForContext = (data: TClientInfoStorage): TMainInfo => {
  return {
    _id: data._id,
    sunrise: data.sunrise,
    sunset: data.sunset,
    birthTown: data.birthTown,
    first_name: data.first_name,
    last_name: data.last_name,
    surname: data.surname,
    first_name_changed: data.first_name_changed,
    last_name_changed: data.last_name_changed,
    surname_changed: data.surname_changed,
    birthday: data.birthday.toString(),
    weekDay: data.weekDay,
    weekDayNum: data.weekDayNum,
    lang: data.lang,
    sex: data.sex,
    dateConsultation: data.dateConsultation
  }
}

export const mainInfoDefaultState = {
  _id: "",
  birthTown: "",
  birthday: "",
  first_name: "",
  first_name_changed: "",
  lang: "",
  last_name: "",
  last_name_changed: "",
  sex: "",
  sunrise: "",
  sunset: "",
  surname: "",
  surname_changed: "",
  weekDay: "",
  weekDayNum: "",
  dateConsultation: "",
}

export const ClientContext = createContext<TMainInfo>(mainInfoDefaultState)
