import React, {useContext} from 'react';
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {ClientContext} from "../../../hooks/clientContext";
import {getSingleNumber} from "../../../utils/clientInfoUtils";
import {format} from "date-fns";

const VarnaTable = () => {
  const mainInfo = useContext(ClientContext);


  const rows = React.useMemo(() => {
    const birthday = new Date(mainInfo.birthday);
    const fullDate = getSingleNumber(+format(birthday, 'yyyyMMdd'));
    const day = getSingleNumber(+format(birthday, 'dd'));
    const month = getSingleNumber(+format(birthday, 'MM'));
    const year = getSingleNumber(+format(birthday, 'yyyy'));

    const getValue = (values: number[]) => (
      +values.includes(day) * 40 + +values.includes(month) * 10 + +values.includes(year) * 10 + +values.includes(fullDate) * 40
    )
    return [
      {
        name: 'Брахман',
        val: getValue([3, 6]),
      },
      {
        name: 'Кшатрий',
        val: getValue([1, 9]),
      },
      {
        name: 'Вайшья',
        val: getValue([5, 2]),
      },
      {
        name: 'Шудра',
        val: getValue([4, 7, 8]),
      },
    ]
  }, [mainInfo.birthday])

  return (
    <>
      <Typography variant="h6" mb={2}>Варны</Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell >Варны</TableCell>
              <TableCell >%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.val}</TableCell>
                </TableRow>
              ))}
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default VarnaTable;
