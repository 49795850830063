import React, {useCallback, useContext, useEffect, useState} from 'react';
import CreateUser from "./CreateUser";
import UsersList from "./UsersList";
import {useHttp} from "../../../../hooks/http.hook";
import {AuthContext} from "../../../../hooks/AuthContext";

const UsersTab = () => {
  const { token } = useContext(AuthContext);
  const [error, setError] = useState('');
  const [users, setUsers] = useState([]);

  const { request } = useHttp();

  const getList = useCallback(() => {
    const initExtended = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    request('/api/users/list', initExtended)
      .then((res) => {
        setUsers(res)
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [request, token])

  useEffect(() => {
    getList();
  }, [getList]);

  const deleteUser = useCallback((id: string) => {
    const initExtended = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    request(`/api/users/delete/${id}`, initExtended)
      .then((res) => {
        setUsers(res)
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [request, token]);

  const updateList = useCallback(
    () => {
      getList();
    },
    [getList],
  );


  return (
    <>
      <CreateUser onUpdateList={updateList} />
      <br />
      <UsersList users={users} onDeleteUser={deleteUser} />
      {error}
    </>
  );
}

export default UsersTab;
