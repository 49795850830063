import React, {useCallback, useContext, useState} from 'react';
import {AuthContext} from "../../../../hooks/AuthContext";
import {useHttp} from "../../../../hooks/http.hook";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

type CreateUserT = {
  onUpdateList: () => void,
}
const CreateUser:React.FC<CreateUserT> = ({ onUpdateList }) => {
  const [fields, setFields] = useState({
    email: '',
    admin: false,
  })

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const { token } = useContext(AuthContext);
  const { request } = useHttp();

  const handleChange = useCallback((event: any) => {
    const { name, value, checked } = event.target;
    let newValue: { [x: string]: string | boolean; };

    if (name === 'admin') {
      newValue = {
        [name]: checked,
      };
    } else {
      newValue = {
        [name]: value,
      };
    }

    setSuccess('');
    setError('');

    setFields((prevState) => ({
      ...prevState,
      ...newValue
    }));
  }, []);

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const {email, admin} = fields;

    setSuccess('');
    setError('');

    const initExtended = {
      method: 'POST',
      body: {
        email,
        admin: admin
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    request('/api/auth/register', initExtended)
      .then((res) => {
        setFields({
          email: '',
          admin: false,
        });
        setSuccess(res.message);
        onUpdateList();
      })
      .catch((err) => {
        setError(err.message);
      });

  };
  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography component="h1" variant="h5">
        Создать нового пользователя
      </Typography>
      <Box sx={{ mt: 1 }}>

        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={fields.email}
          onChange={handleChange}
          autoFocus
          size="small"
        />

        <FormControlLabel
          control={(
            <Checkbox
              value={fields.admin}
              name="admin"
              color="primary"
              onChange={handleChange}
            />
          )}
          label="Администратор"
        />
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={(e) => handleSubmit(e)}
        >
          Создать пользователя
        </Button>
        {!!error && (<Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>)}
        {!!success && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {success}
          </Alert>
        )}
        {/*<Grid container>*/}
        {/*  <Grid item xs>*/}
        {/*    <Link href="#" variant="body2">*/}
        {/*      Forgot password?*/}
        {/*    </Link>*/}
        {/*  </Grid>*/}
        {/*  <Grid item>*/}
        {/*    <Link href="#" variant="body2">*/}
        {/*      {"Don't have an account? Sign Up"}*/}
        {/*    </Link>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
      </Box>
    </Box>
  );
}

export default CreateUser;
