import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';

type TCharPoint = {
  x: string,
  y: number
}

type TChart = {
  data: TCharPoint[]
}

const Chart: React.FC<TChart> = ({ data }) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 10,
            right: 10,
            bottom: 0,
            left: -10,
          }}
        >
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis
            dataKey="x"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          />
          <YAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            domain={[0, 9]}
            interval={0}
            ticks={[1,2,3,4,5,6,7,8,9]}
          />
          <Line
            isAnimationActive={false}
            type="linear"
            dataKey="y"
            stroke={theme.palette.primary.main}
          />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}

export default Chart;
