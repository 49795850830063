import React, {useContext, useMemo} from 'react';
import {differenceInYears, format} from 'date-fns'

import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from '@mui/material/TableHead';
import TableCell from "@mui/material/TableCell";
import {ClientContext} from "../../../hooks/clientContext";
import {getSingleNumber, getSumFromStringNumber} from "../../../utils/clientInfoUtils";
import {useTheme} from "@mui/material/styles";
import {getRangeOfYears, getSumDayMonth} from "../../../utils/biorhytmsUtils";

const BiorhythmsShort = () => {
  const mainInfo = useContext(ClientContext);
  const theme = useTheme();

  const currentYear = new Date(mainInfo.dateConsultation).getFullYear();
  const birthday = new Date(mainInfo.birthday);
  const dayAndMonth = getSumDayMonth(birthday);
  const rangeYears = getRangeOfYears();

  const age = useMemo(
    () => differenceInYears(new Date(`${currentYear}`), new Date(mainInfo.birthday)),
    [currentYear, mainInfo.birthday]
  );

  const ageEndYear = useMemo(
    () => differenceInYears(new Date(`${currentYear}-12-31`), new Date(mainInfo.birthday)),
    [currentYear, mainInfo.birthday]
  );

  const rowsMain = useMemo(() => {
    const currentDate = new Date(mainInfo.dateConsultation);

    const personalYear = getSingleNumber(dayAndMonth + getSumFromStringNumber(new Date(mainInfo.dateConsultation).getFullYear().toString()));
    const personalMonth = getSingleNumber(personalYear + getSumFromStringNumber(format(currentDate, 'MM')));
    const personalDay = getSingleNumber(personalMonth + getSumFromStringNumber(format(currentDate, 'dd')));
    return [
      {
        name: 'ЛГ',
        val: personalYear
      },
      {
        name: 'ЛМ',
        val: personalMonth
      },
      {
        name: 'ЛД',
        val: personalDay
      },
      {
        name: 'ЧВВ',
        val: getSingleNumber(age + ageEndYear)
      },
    ]
  }, [age, ageEndYear, dayAndMonth])
  const rows = useMemo(() => rangeYears.map((year) => {
    return {
      year: year,
      lg: getSingleNumber(dayAndMonth + getSumFromStringNumber(`${year}`)),
      tt: '???',
      lag: 0
    }
  }), [dayAndMonth, rangeYears])

  return (
    <>
      <Typography variant="h6" mb={2}>Биоритм</Typography>
      <TableContainer sx={{ mb: 2 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell >Лет</TableCell>
              <TableCell >{age}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {rowsMain.map((row) => (
                <TableRow key={row.name}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.val}</TableCell>
                </TableRow>
              ))}
            </>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer>
        <Table size="small" aria-label="a bio table">
          <TableHead>
            <TableRow>
              <TableCell >Год</TableCell>
              <TableCell >ЛГ</TableCell>
              <TableCell >Т-т</TableCell>
              <TableCell >ЛАГ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rows.map((row) => (
                <TableRow
                  key={row.year}
                  sx={{ backgroundColor: row.year === currentYear ? theme.palette.grey["300"] : '' }}
                >
                  <TableCell component="th" scope="row">{row.year}</TableCell>
                  <TableCell align="center">{row.lg}</TableCell>
                  <TableCell align="center">{row.tt}</TableCell>
                  <TableCell align="center">{row.lag}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default BiorhythmsShort;
