import React, {useCallback, useContext, useMemo} from 'react';
import {format} from "date-fns";

import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from '@mui/material/TableHead';
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import {useTheme} from "@mui/material";
import Chart from "../../../components/Chart";
import {ClientContext} from "../../../hooks/clientContext";

// @ts-ignore
const arrCell = [...Array(7).keys()];
const planetArr = ['Ме', 'Юп', 'Ве', 'Са', 'Со', 'Лу', 'Ма'];
const rows = [
  {
    col1: 'Лу',
    col2: 'Ме',
  },
  {
    col1: 'Ме',
    col2: 'Юп',
  },
  {
    col1: 'Ве',
    col2: 'Ве',
  },
  {
    col1: 'Со',
    col2: 'Са',
  },
  {
    col1: 'Со',
    col2: 'Со',
  },
  {
    col1: 'Со',
    col2: 'Лу',
  },
  {
    col1: 'Ма',
    col2: 'Ма',
  },
  {
    col1: 'Ма',
    col2: 'Ме',
  },
  {
    col1: 'Юп',
    col2: 'Юп',
  },
  {
    col1: 'Юп',
    col2: 'Ве',
  },
  {
    col1: 'Са',
    col2: 'Са',
  },
  {
    col1: 'Са',
    col2: 'Со',
  },
  {
    col1: 'Са',
    col2: 'Лу',
  },
  {
    col1: 'Лу',
    col2: 'Ма',
  },
  {
    col1: null,
    col2: 'Ме',
  },
];

const DynamicHoroscope = () => {
  const mainInfo = useContext(ClientContext);
  const theme = useTheme();

  const createData = useCallback((data: number[]) => {
    const defaultPosition = {x: '0', y: data[data.length - 1]};
    const dataComplete = data.map((item, i) => ({ x: planetArr[i], y: item}))
    return [ defaultPosition, ...dataComplete  ];
  }, [])

  const chik = useMemo((): string => {
    const birthday = new Date(mainInfo.birthday);
    const date = (+format(birthday, 'ddMM') * +format(birthday, 'yyyy')).toString();

    return date.length === 7 ? date : `0${date}`;
  }, [mainInfo.birthday]);

  return (
    <>
      <Typography variant="h6" mb={2}>Динамический гороскоп</Typography>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: 300,
        }}
      >
        <Chart data={createData(chik.split('').map((item) => +item))} />
      </Paper>
      <TableContainer>
        <Table size="small" aria-label="a horoscope table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} sx={{ borderRight: `2px solid ${theme.palette.grey["300"]}` }}><b>ЧИК</b></TableCell>
              <>
                {
                  chik.split('').map((item, index) => (
                    <TableCell
                      key={item + Math.random()}
                      sx={{ borderRight: `1px solid ${theme.palette.grey["300"]}` }}
                      align="center"
                    >
                      {item}
                    </TableCell>
                  ))
                }
              </>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderRight: `2px solid ${theme.palette.grey["300"]}` }}><b>Су</b></TableCell>
              <TableCell sx={{ borderRight: `2px solid ${theme.palette.grey["300"]}` }}><b>Лу</b></TableCell>
              <>
                {planetArr.map((planet) => (
                  <TableCell
                    key={planet}
                    sx={{ borderRight: `1px solid ${theme.palette.grey["300"]}` }}
                    align="center"
                  >
                    {planet}
                  </TableCell>
                ))}
              </>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rows.map((row, ind) => (
                <TableRow key={ind}>
                  <TableCell component="th" scope="row" sx={{ borderRight: `2px solid ${theme.palette.grey["300"]}` }}>
                    <b>{row.col1}</b>
                  </TableCell>
                  <TableCell component="th" scope="row" sx={{ borderRight: `2px solid ${theme.palette.grey["300"]}` }}>
                    <b>{row.col2}</b>
                  </TableCell>
                  <>
                    {
                      arrCell.map((item) => {
                        const num = (item + 1) + (ind * 7);
                        return (
                          <TableCell
                            align="center"
                            key={num}
                            sx={{ borderRight: `1px solid ${theme.palette.grey["300"]}` }}
                          >
                            {num%12 === 0 ? <b>{num}</b> : num}
                          </TableCell>
                        )
                      })
                    }
                  </>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default DynamicHoroscope;
