import React, { useContext } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import TemplatePage from "../TemplatePage";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import {AuthContext} from "../../hooks/AuthContext";
import UsersTab from "./components/UsersTab/UsersTab";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AccountPage = () => {
  const { isAdmin } = useContext(AuthContext);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <TemplatePage>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Мой аккаунт" {...a11yProps(0)} />
            {isAdmin && <Tab label="Пользователи" {...a11yProps(1)} />}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ChangePassword/>
        </TabPanel>
        {isAdmin && (
          <TabPanel value={value} index={1}>
            <UsersTab />
          </TabPanel>
        )}

      </Box>
    </TemplatePage>
  );
}

export default AccountPage;
