import React, {useContext} from 'react';

import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from '@mui/material/TableHead';
import TableCell from "@mui/material/TableCell";
import {
  getEnergyName,
  getSingleNumber,
} from "../../../utils/clientInfoUtils";
import {ClientContext} from "../../../hooks/clientContext";
import {useTheme} from "@mui/material";
import {useCountBaseInfo} from "../../../hooks/useCountBaseInfo";

const arrayForPotential = ['ЧД','ЧЖП','ЧС','ЧЗ','ЧЖ','ЧЛ','ЧЭ','ЧТ','ЧСт','ЧБ','ЧТЗ']
const PlansExpressionTable = () => {
  const theme = useTheme()

  const mainInfo = useContext(ClientContext);
  const baseInfoData = useCountBaseInfo();

  const filteredBaseInfo = React.useMemo(() => {
    return [...baseInfoData.main, ...baseInfoData.addition]
      .filter((item) => arrayForPotential.includes(item.name))
  }, [baseInfoData.addition, baseInfoData.main]);

  console.log('filteredBaseInfo////', filteredBaseInfo)

  const rows = React.useMemo(() => {
    const fullName = `${mainInfo.last_name}${mainInfo.first_name}${mainInfo.surname}`;
    const energyObj = getEnergyName(fullName);

    const filterByNumber = (numberArr: number[]) => {
      return filteredBaseInfo.filter((item) => numberArr.includes(item.col2 as number))
    };

    const countTheNumber = (arrNumber: number[], multi: boolean = false): number => {
      return arrNumber.reduce((acc, item: number) => getSingleNumber(acc + energyObj[item] * (multi ? item : 1)), 0)
    };

    const getPotential = (arrNumber: number[]): number => {
      return countTheNumber(arrNumber) + filterByNumber(arrNumber).length + (+arrNumber.includes(countTheNumber(arrNumber, true)) * 2)
    }
    return {
      main: [
        {
          name: "ФПВ",
          col2: countTheNumber([4, 5]),
          col3: getPotential([4, 5]),
          col4: countTheNumber([4, 5], true),
        },
        {
          name: "МПВ",
          col2: countTheNumber([1, 8]),
          col3: getPotential([1, 8]),
          col4: countTheNumber([1, 8], true),
        },
        {
          name: "ЭПВ",
          col2: countTheNumber([2, 3, 6]),
          col3: getPotential([2, 3, 6]),
          col4: countTheNumber([2, 3, 6], true),
        },
        {
          name: "ИПВ",
          col2: countTheNumber([7, 9]),
          col3: getPotential([7, 9]),
          col4: countTheNumber([7, 9], true),
        },
      ],
    }
  }, [filteredBaseInfo, mainInfo.first_name, mainInfo.last_name, mainInfo.surname]);

  return (
    <>
      <Typography variant="h6" mb={2}>Планы выражения</Typography>
      <TableContainer>
        <Table size="small" aria-label="a expression table">
          <TableHead>
            <TableRow>
              <TableCell>ПВ</TableCell>
              <TableCell align="right" sx={{ borderRight: `2px solid ${theme.palette.grey["300"]}` }}>Бал</TableCell>
              <TableCell align="right">Пот</TableCell>
              <TableCell align="right">СЗ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rows.main.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">{row.name}</TableCell>
                  <TableCell align="right" sx={{ borderRight: `2px solid ${theme.palette.grey["300"]}` }}>
                    {row.col2}
                  </TableCell>
                  <TableCell align="right">{row.col3}</TableCell>
                  <TableCell align="right">{row.col4}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default PlansExpressionTable;
