import React, {useContext} from 'react';

import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import {ClientContext} from "../../../hooks/clientContext";
import {useTheme} from "@mui/material";
import {useCountBaseInfo} from "../../../hooks/useCountBaseInfo";

const BaseInfoTable = () => {
  const theme = useTheme()
  const mainInfo = useContext(ClientContext);
  console.log('BaseInfoTable', mainInfo)

  const rows = useCountBaseInfo();

  return (
    <>
      <Typography variant="h6" mb={2}>Базовые показатели</Typography>
      <TableContainer>
        <Table size="small" aria-label="a base table">
          <TableBody>
            {
              rows.main.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row"><b>{row.name}</b></TableCell>
                  <TableCell align="right" sx={{ borderRight: `2px solid ${theme.palette.grey["300"]}` }}>
                    <b>{row.col2}</b>
                  </TableCell>
                  <TableCell align="right">{row.col3}</TableCell>
                  <TableCell align="right">{row.col4}</TableCell>
                </TableRow>
              ))
            }

            <TableRow>
              <TableCell component="th" scope="row" colSpan={4}>
                Дополнительные показатели
              </TableCell>
            </TableRow>

            {
              rows.addition.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
                >
                  <TableCell component="th" scope="row">{row.name}</TableCell>
                  <TableCell align="right" sx={{ borderRight: `2px solid ${theme.palette.grey["300"]}` }}>
                    {row.col2}
                  </TableCell>
                  <TableCell align="right">{row.col3}</TableCell>
                  <TableCell align="right">{row.col4}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default BaseInfoTable;
