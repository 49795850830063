import {getSumFromStringNumber} from "./clientInfoUtils";
import {format} from "date-fns";
import ru from "date-fns/locale/ru";
import uk from "date-fns/locale/uk";
import {ENUM_LANG} from "../consts/clientInfoConst";

export const getRangeOfYears = () => {
  const currentYear = new Date().getFullYear();
  let start = currentYear - 8; // Starting number of the range
  let end = currentYear + 6; // Ending number of the range
  return Array.from({length: end - start + 1}, (_, idx) => start + idx);
}

export const getSumDayMonth = (birthday: Date) => {
  return getSumFromStringNumber(format(birthday, 'MM')) + getSumFromStringNumber(format(birthday, 'dd'));
}

export const getAllMonthName = (lang: string = ENUM_LANG.RU) => {
  const langs: {[key: string]: any} = {
    [ENUM_LANG.UA]: uk,
    [ENUM_LANG.RU]: ru
  }

  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => {
    return format(new Date(`2023-${i}-01`), 'LLLL', {locale: langs[lang]});
  })
}
