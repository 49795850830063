import {useState, useCallback, useEffect} from 'react'

const storageName = 'userData';

export const useAuth = () => {
  const [token, setToken] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [ready, setReady] = useState(false);

  const login = useCallback((jwtToken: string, id: string, email: string, isAdmin: boolean) => {
    setToken(jwtToken);
    setUserEmail(email);
    setIsAdmin(isAdmin)

    localStorage.setItem(storageName, JSON.stringify({
      userId: id, token: jwtToken, email, isAdmin,
    }));
  }, [])


  const logout = useCallback(() => {
    setToken('');
    setUserEmail('');
    setIsAdmin(false);
    localStorage.removeItem(storageName);
  }, [])

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(storageName) as string);

    if (data?.token) {
      login(data.token, data.userId, data.email, data.isAdmin)
    }
    setReady(true)
  }, [login]);

  // temporary solution when clients is empty
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('clients') as string);

    const ids = data && Object.keys(data);
    if(ids?.some((id: string) => !data[id].last_name)){
      localStorage.removeItem('clients');
    }
  }, []);



  return { login, logout, token, userEmail, ready, isAdmin }
}
