import React, {useContext} from "react";
import {
  getEnergyName,
  getFilteredEmphatic,
  getFilteredNoEmphatic,
  getNumberFromString,
  getSingleNumber,
  getSumFromStringNumber
} from "../utils/clientInfoUtils";
import {format} from "date-fns";
import {PlanetDay} from "../consts/clientInfoConst";
import {ClientContext} from "./clientContext";


export const useCountBaseInfo = () => {
  const mainInfo = useContext(ClientContext);

  return React.useMemo(() => {
    const birthday = new Date(mainInfo.birthday);
    // const sunriseHour = mainInfo.sunrise.split(':')[0];
    const fullName = `${mainInfo.last_name}${mainInfo.first_name}${mainInfo.surname}`;
    const countedName = getNumberFromString(mainInfo.first_name);
    const countedLastName = getNumberFromString(mainInfo.last_name);
    const countedSurname = getNumberFromString(mainInfo.surname);
    const energyName = getEnergyName(fullName);

    const filterEnergyValue = (filterNumber: number) => {
      return Object.entries(energyName).map((item) => {
        const [key, value] = item
        if (value === filterNumber) {
          return key
        }
        return undefined;
      }).join('') || 'нет'
    }

    const chWayCol2 = getSumFromStringNumber(format(birthday, 'yyyyMMdd'));
    const chWayCol4 = getSingleNumber(getSumFromStringNumber(format(birthday, 'yyyy')))
      + getSingleNumber(getSumFromStringNumber(format(birthday, 'MM')))
      + getSingleNumber(getSumFromStringNumber(format(birthday, 'dd')));

    const chDestinyCol2 = getNumberFromString(fullName);
    const chDestinyCol4 = getSingleNumber(countedLastName)
      + getSingleNumber(countedName) + getSingleNumber(countedSurname);
    const chWishCol2 = getNumberFromString(getFilteredEmphatic(fullName));
    const chWishCol4 = getSingleNumber(getNumberFromString(getFilteredEmphatic(mainInfo.last_name)))
      + getSingleNumber(getNumberFromString(getFilteredEmphatic(mainInfo.first_name)))
      + getSingleNumber(getNumberFromString(getFilteredEmphatic(mainInfo.surname)));
    const chPersonCol2 = getNumberFromString(getFilteredNoEmphatic(fullName));
    const chPersonCol4 = getSingleNumber(getNumberFromString(getFilteredNoEmphatic(mainInfo.last_name)))
      + getSingleNumber(getNumberFromString(getFilteredNoEmphatic(mainInfo.first_name)))
      + getSingleNumber(getNumberFromString(getFilteredNoEmphatic(mainInfo.surname)));

    const chExpressCol2 = getSumFromStringNumber(format(birthday, 'MMdd'));
    const chBlagoCik2 = getSumFromStringNumber(format(birthday, 'MMyyyy'));
    const chTacktick = getSingleNumber(getSumFromStringNumber(format(birthday, 'MM')));
    const chSoulT = getSingleNumber(getSumFromStringNumber(format(birthday, 'yyyy')));
    const chMaxValue = filterEnergyValue(Math.max(...Object.values(energyName)));
    const chMinValue = filterEnergyValue(0);
    return {
      main: [
        {
          name: "ЧД",
          col2: getSingleNumber(+format(birthday, 'dd')),
          col3: format(birthday, 'dd'),
          col4: null
        },
        {
          name: "ЧЖП",
          col2: getSingleNumber(chWayCol2),
          col3: `${chWayCol2}/${getSingleNumber(chWayCol2)}`,
          col4: `${chWayCol4}/${getSingleNumber(chWayCol4)}`
        },
        {
          name: "ЧС",
          col2: getSingleNumber(chDestinyCol2),
          col3: `${chDestinyCol2}/${getSingleNumber(chDestinyCol2)}`,
          col4: `${chDestinyCol4}/${getSingleNumber(chDestinyCol4)}`
        },
        {
          name: "ЧЗ",
          col2: getSingleNumber(chWayCol2 + chDestinyCol2),
          col3: getSingleNumber(chWayCol2) + getSingleNumber(chDestinyCol2),
          col4: null
        },
        {
          name: "ЧЖ",
          col2: getSingleNumber(chWishCol2),
          col3: `${chWishCol2}/${getSingleNumber(chWishCol2)}`,
          col4: `${chWishCol4}/${getSingleNumber(chWishCol4)}`,
        },
        {
          name: "ЧЛ",
          col2: getSingleNumber(chPersonCol2),
          col3: `${chPersonCol2}/${getSingleNumber(chPersonCol2)}`,
          col4: `${chPersonCol4}/${getSingleNumber(chPersonCol4)}`,
        },
        {
          name: "ЧИЗ",
          col2: chMaxValue,
          col3: null,
          col4: null,
        },
        {
          name: "ЧНЗ",
          col2: chMinValue,
          col3: null,
          col4: null,
        },
        {
          name: "ЧЭ",
          col2: getSingleNumber(chExpressCol2),
          col3: getSingleNumber(chExpressCol2),
          col4: null,
        },
        {
          name: "ЧТ",
          col2: chTacktick,
          col3: chTacktick,
          col4: null,
        },
        {
          name: "ЧСт",
          col2: chSoulT,
          col3: chSoulT,
          col4: null,
        },
        {
          name: "ЧБ",
          col2: getSingleNumber(chBlagoCik2),
          col3: getSingleNumber(chBlagoCik2),
          col4: null,
        },
      ],
      addition: [
        {
          name: "ЧЛУ",
          col2: getSingleNumber(countedName),
          col3: countedName,
          col4: null
        },
        {
          name: "ЧСС",
          col2: getSingleNumber(countedLastName),
          col3: countedLastName,
          col4: null
        },
        {
          name: "ЧСР",
          col2: getSingleNumber(countedSurname),
          col3: countedSurname,
          col4: null
        },
        {
          name: "ЧИ",
          col2: getSingleNumber(chDestinyCol2),
          col3: `${chDestinyCol2}/${getSingleNumber(chDestinyCol2)}`,
          col4: `${chDestinyCol4}/${getSingleNumber(chDestinyCol4)}`
        },
        {
          name: "ЧТЗ",
          col2: getSingleNumber(fullName.length),
          col3: fullName.length,
          col4: null
        },
        {
          name: "ЧИВ",
          col2: getSingleNumber(getSumFromStringNumber(format(birthday, 'dd')) + getSingleNumber(mainInfo.first_name.length)),
          col3: getSumFromStringNumber(format(birthday, 'dd')) + getSingleNumber(mainInfo.first_name.length),
          col4: null
        },
        {
          name: "ЧО",
          col2: getSingleNumber(getSumFromStringNumber(format(birthday, 'dd')) + getSingleNumber(countedName)),
          col3: getSumFromStringNumber(format(birthday, 'dd')) + getSingleNumber(countedName),
          col4: null
        },
        {
          name: "ЧВР",
          col2: getSingleNumber(getNumberFromString(`${mainInfo.first_name.slice(0, 1)}${mainInfo.last_name.slice(0, 1)}${mainInfo.surname.slice(0, 1)}`)),
          col3: getNumberFromString(`${mainInfo.first_name.slice(0, 1)}${mainInfo.last_name.slice(0, 1)}${mainInfo.surname.slice(0, 1)}`),
          col4: null
        },
        {
          name: "ПУР",
          // col2: PlanetDay[(+format(birthday, 'HH') > +sunriseHour ? `${+mainInfo.weekDayNum - 1}` : mainInfo.weekDayNum)],
          col2: PlanetDay[mainInfo.weekDayNum],
          col3: null,
          col4: null
        },
        {
          name: "ЧДР",
          col2: "?",
          col3: null,
          col4: null
        },
        {
          name: "ПУЧР",
          col2: "?",
          col3: null,
          col4: null
        },
        {
          name: "с ПУЧР",
          col2: "?",
          col3: null,
          col4: null
        },
        {
          name: "ЧЧР",
          col2: '?',
          col3: null,
          col4: null
        },
      ]
    }
  }, [mainInfo.birthday, mainInfo.first_name, mainInfo.last_name, mainInfo.surname, mainInfo.weekDayNum]);
}
