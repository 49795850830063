import React from 'react';
import { BrowserRouter } from "react-router-dom";

import routes from "./routes";
import {useAuth} from "./hooks/useAuth";
import {AuthContext} from './hooks/AuthContext';

function App() {
  const {token, login, logout, ready, userEmail, isAdmin} = useAuth();

  const value = {
    token,
    userEmail,
    isAdmin,
    onLogin: login,
    onLogout: logout,
  };

  if (!ready) {
    return null;
  }

  return (
    <BrowserRouter>
      <AuthContext.Provider value={value}>
        {routes}
      </AuthContext.Provider>
    </BrowserRouter>
  );
}

export default App;
