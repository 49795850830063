import React, {useContext, useState} from "react";
import {Navigate, useLocation, useNavigate, NavLink} from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import {useHttp} from "../../hooks/http.hook";
import {AuthContext} from "../../hooks/AuthContext";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.vigraha.net/">
        Vigraha
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignIn() {
  const { onLogin, token } = useContext(AuthContext);
  const { request } = useHttp();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email') as string;

    const initExtended = {
      method: 'POST',
      body: {
        email,
        password: data.get('password'),
      }
    }

    request('/api/auth/login', initExtended)
      .then((res) => {
        onLogin(res.token, res.userId, email, res.admin);
        navigate('/');
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  if (token) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Box mt={1}>
            <NavLink to="/remind-password">
              <Typography variant='body2'>
                Forgot password?
              </Typography>
            </NavLink>
          </Box>

        </Box>
      </Box>
      {!!error && (<Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {error}
      </Alert>)}
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>

  );
}
