import React, {useContext} from 'react';
import {format} from "date-fns";

import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {ClientContext} from "../../../hooks/clientContext";

const TrigonsTable = () => {
  const mainInfo = useContext(ClientContext);

  const rows = React.useMemo(() => {
    const shortDate = format(new Date(mainInfo.birthday), 'yyMMdd');
    const fullDate = format(new Date(mainInfo.birthday), 'yyyyMMdd');

    const getValue = (value: number, date: string = shortDate) =>
      date.split('').filter((item) => +item === value).join('');

    return {
      past: [
        {
          name: 'Дхарма',
          col1: <>3 <b>{getValue(3)}</b></>,
          col2: <>1 <b>{getValue(1)}</b></>,
          col3: <>9 <b>{getValue(9)}</b></>,
        },
        {
          name: 'Артха',
          col1: <>6 <b>{getValue(6)}</b></>,
          col2: <>7 <b>{getValue(7)}</b></>,
          col3: <>5 <b>{getValue(5)}</b></>,
        },
        {
          name: 'Кама',
          col1: <>2 <b>{getValue(2)}</b></>,
          col2: <>8 <b>{getValue(8)}</b></>,
          col3: <>4 <b>{getValue(4)}</b></>,
        },
      ],
      current: [
        {
          name: 'Дхарма',
          col1: <>3 <b>{getValue(3, fullDate)}</b></>,
          col2: <>6 <b>{getValue(6, fullDate)}</b></>,
          col3: <>9 <b>{getValue(9, fullDate)}</b></>,
        },
        {
          name: 'Артха',
          col1: <>2 <b>{getValue(2, fullDate)}</b></>,
          col2: <>5 <b>{getValue(5, fullDate)}</b></>,
          col3: <>8 <b>{getValue(8, fullDate)}</b></>,
        },
        {
          name: 'Кама',
          col1: <>1 <b>{getValue(1, fullDate)}</b></>,
          col2: <>4 <b>{getValue(4, fullDate)}</b></>,
          col3: <>7 <b>{getValue(7, fullDate)}</b></>,
        },
      ]
    }
  }, [mainInfo.birthday])

  return (
    <>
      <Typography variant="h6" mb={2}>Тригоны прошлого</Typography>
      <TableContainer sx={{mb: 3}}>
        <Table size="small">
          <TableBody>
            <>
              {rows.past.map((row) => (
                <TableRow key={row.name}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.col1}</TableCell>
                  <TableCell>{row.col2}</TableCell>
                  <TableCell>{row.col3}</TableCell>
                </TableRow>
              ))}
            </>
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h6" mb={2}>Тригоны настоящего</Typography>
      <TableContainer>
        <Table size="small">
          <TableBody>
            <>
              {rows.current.map((row) => (
                <TableRow key={row.name}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.col1}</TableCell>
                  <TableCell>{row.col2}</TableCell>
                  <TableCell>{row.col3}</TableCell>
                </TableRow>
              ))}
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default TrigonsTable;
