import React, {memo, useContext} from 'react';

import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from '@mui/material/TableHead';
import TableCell from "@mui/material/TableCell";
import {useTheme} from "@mui/material";

import {ClientContext} from "../../../hooks/clientContext";
import {getSingleNumber, getSumFromStringNumber} from "../../../utils/clientInfoUtils";
import {format} from "date-fns";

const PredictVolume = memo(() => {
  const theme = useTheme();
  const mainInfo = useContext(ClientContext);

  const borderRight = `1px solid ${theme.palette.grey["300"]}`;
  const birthday = new Date(mainInfo.birthday);
  const chWay = getSingleNumber(getSumFromStringNumber(format(birthday, 'yyyyMMdd')));
  const yearOld = chWay > 2 ? 36 - chWay : 27 - chWay;
  const year = +format(birthday, 'yyyy') + yearOld;

  const daySum = getSumFromStringNumber(format(birthday, 'dd'));
  const monthSum = getSumFromStringNumber(format(birthday, 'MM'));
  const yearSum = getSumFromStringNumber(format(birthday, 'yyyy'));

  const chP1 = getSingleNumber(daySum) + getSingleNumber(monthSum);
  const chP2 = getSingleNumber(daySum) + getSingleNumber(yearSum);
  const chP4 = getSingleNumber(monthSum) + getSingleNumber(yearSum);

  const chV1 = getSingleNumber(Math.abs(getSingleNumber(daySum) - getSingleNumber(monthSum)));
  const chV2 = getSingleNumber(Math.abs(getSingleNumber(yearSum) - getSingleNumber(daySum)));
  const chV4 = getSingleNumber(Math.abs(getSingleNumber(yearSum) - getSingleNumber(monthSum)));
  return (
    <>
      <Typography variant="h6" mb={2}>Предиктивные показатели</Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell >Циклы</TableCell>
              <TableCell sx={{borderRight}} >ЧОЦ</TableCell>
              <TableCell sx={{borderRight}} colSpan={2}>Периоды, год</TableCell>
              <TableCell sx={{borderRight}} colSpan={2}>ЧП</TableCell>
              <TableCell >ЧВ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>1.Молодость</TableCell>
              <TableCell sx={{borderRight}}>{getSingleNumber(monthSum)}/{monthSum}</TableCell>
              <TableCell>0 - {yearOld}</TableCell>
              <TableCell sx={{borderRight}}>{year}</TableCell>
              <TableCell>{getSingleNumber(chP1)}</TableCell>
              <TableCell sx={{borderRight}}>{chP1}</TableCell>
              <TableCell>{chV1}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={3}>2.Зрелось</TableCell>
              <TableCell sx={{borderRight}} rowSpan={3}>
                {getSumFromStringNumber(format(birthday, 'dd'))}/{format(birthday, 'dd')}
              </TableCell>
              <TableCell>{yearOld + 1} - {yearOld + 9}</TableCell>
              <TableCell sx={{borderRight}}>{year + 9}</TableCell>
              <TableCell>{getSingleNumber(chP2)}</TableCell>
              <TableCell sx={{borderRight}}>{chP2}</TableCell>
              <TableCell>{chV2}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{yearOld + 1 + 9} - {yearOld + 2*9}</TableCell>
              <TableCell sx={{borderRight}}>{year + 2*9}</TableCell>
              <TableCell>{getSingleNumber(chP1 + chP2)}</TableCell>
              <TableCell sx={{borderRight}}>{chP1 + chP2}</TableCell>
              <TableCell>{Math.abs(chV2 - chV1)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{yearOld + 1 + 2*9} - {yearOld + 3*9}</TableCell>
              <TableCell sx={{borderRight}}>{year + 3*9}</TableCell>
              <TableCell>{getSingleNumber(chP4)}</TableCell>
              <TableCell sx={{borderRight}}>{chP4}</TableCell>
              <TableCell>{chV4}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3.Старость</TableCell>
              <TableCell sx={{borderRight}}>{getSingleNumber(yearSum)}/{yearSum}</TableCell>
              <TableCell>После {yearOld + 3*9}</TableCell>
              <TableCell sx={{borderRight}}>{year + 3*9}</TableCell>
              <TableCell>{getSingleNumber(chP4)}</TableCell>
              <TableCell sx={{borderRight}}>{chP4}</TableCell>
              <TableCell>{chV4}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
})

export default PredictVolume;
