import React, {useCallback, useState} from "react";
import { NavLink } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import {useHttp} from "../../hooks/http.hook";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { NAV } from "../../consts/navigation";

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.vigraha.net/">
        Vigraha
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function RemindPassword() {
  const { request } = useHttp();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = useCallback((event: any) => {
    setEmail(event.target.value);
    setError('')
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const initExtended = {
      method: 'POST',
      body: {
        email,
      }
    }

    request('/api/auth/forgot-pass', initExtended)
      .then((res) => {
        setSuccess(res.message);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Восстановить пароль
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {!!success ? (<Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            Новый пароль отправлен на email {email}.
            <br />
            Авторизуйтесь по ссылке <NavLink to={NAV.SIGN_IN}>Sign In</NavLink>
          </Alert>) : (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={handleChange}
                autoFocus
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Восстановить пароль
              </Button>
              <Box mt={1}>
                <NavLink to={NAV.SIGN_IN}>
                  <Typography variant='body2'>
                    Sign In
                  </Typography>
                </NavLink>
              </Box>
            </>
          )}

        </Box>
      </Box>
      <br />
      {!!error && (<Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {error}
      </Alert>)}

      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
