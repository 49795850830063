import {EMPHATIC, ENUM_LANG, NUMBER_OF_LETTER} from "../consts/clientInfoConst";

export const getNumberFromString = (name: string, lang: string = ENUM_LANG.RU) => {
  return name.toLowerCase().split('')
    .reduce((acc, cur) => acc + NUMBER_OF_LETTER[lang][cur] ?? 0, 0);
}

export const getSumFromStringNumber = (string: string) =>
  string.split('').reduce((acc, cur) => acc + +cur, 0);

export const getSingleNumber = (number: number): number => {
  if (number > 9) {
    const count = number.toString().split('')
      .reduce((acc: number, cur: string) => acc + +cur, 0);

    return getSingleNumber(count)
  }

  return number;
}

export const getFilteredEmphatic = (name: string, lang: string = ENUM_LANG.RU): string => {
  return name.toLowerCase().split('').filter((item) => EMPHATIC[lang].includes(item)).join('')
}

export const getFilteredNoEmphatic = (name: string, lang: string = ENUM_LANG.RU): string => {
  return name.toLowerCase().split('').filter((item) => !EMPHATIC[lang].includes(item)).join('')
}

export const getEnergyName = (name:string, lang: string = ENUM_LANG.RU) => {
  const energies: {[key: string]: number} = {
    '1': 0,
    '2': 0,
    '3': 0,
    '4': 0,
    '5': 0,
    '6': 0,
    '7': 0,
    '8': 0,
    '9': 0,
  }
  return name.toLowerCase().split('').reduce((acc, cur) => {
    return { ...acc, [`${NUMBER_OF_LETTER[lang][cur]}`]: acc[`${NUMBER_OF_LETTER[lang][cur]}`] + 1}
  }, energies)
}
