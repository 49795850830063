import React, {memo, useCallback, useContext} from 'react';
import {format} from "date-fns";

import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import Chart from "../../../components/Chart";
import {ClientContext} from "../../../hooks/clientContext";
import Grid from "@mui/material/Grid";
import {getSumFromStringNumber} from "../../../utils/clientInfoUtils";
import Paper from "@mui/material/Paper";

const ageArr = ['12', '24', '36', '48', '60', '72', '84', '96'];

const IntenseTable = memo(() => {
  const mainInfo = useContext(ClientContext);

  const birthday = new Date(mainInfo.birthday);
  const day = format(birthday, 'dd');
  const month = format(birthday, 'MM');
  const year = format(birthday, 'yyyy');
  const udr = getSumFromStringNumber(format(birthday, 'yyyyMMdd'));
  const yep = (+day * +month * +year).toString();

  const createData = useCallback((data: number[]) => {
    const defaultPosition = {x: '0', y: data[0]};
    data.push(data[0])
    data.push(data[1])
    const dataComplete = data.map((item, i) => ({ x: ageArr[i], y: item}))
    return [ defaultPosition, ...dataComplete  ];
  }, [])

  return (
    <>
      <Typography variant="h6" mb={2}>Нумерология интенсивности</Typography>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: 300,
          mb: 2,
        }}
      >
        <Chart data={createData(yep.split('').map((item) => +item))} />
      </Paper>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>УДР</TableCell>
                  <TableCell>{udr}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>СЭП</TableCell>
                  <TableCell>{udr.toString().charAt(0)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item sm={4}>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>УПТ</TableCell>
                  <TableCell>{format(birthday, 'dd')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>УПЭ</TableCell>
                  <TableCell>{+format(birthday, 'MM')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>УПМ</TableCell>
                  <TableCell>{getSumFromStringNumber(format(birthday, 'yyyy'))}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item sm={4}>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>ПР</TableCell>
                  <TableCell>{getSumFromStringNumber((+day * +month * +year).toString())}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ПХ</TableCell>
                  <TableCell>{getSumFromStringNumber((+(day + month) * +year).toString())}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ПБ</TableCell>
                  <TableCell>{getSumFromStringNumber((+day * +(month + year)).toString())}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Typography variant="body1">УЭП: {yep}</Typography>
    </>
  );
})

export default IntenseTable;
