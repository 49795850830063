import React from "react";

type AuthContextT = {
  token: string,
  userEmail: string,
  onLogin: (token: string, id: string, email: string, isAdmin: boolean) => void,
  onLogout: () => void,
  isAdmin: boolean,
}

export const AuthContext = React.createContext<AuthContextT>({
  token: '',
  userEmail: '',
  onLogout(): void {},
  onLogin(): void {},
  isAdmin: false,
});
