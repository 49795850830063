type navType = {
  [key: string]: string,
}
export const NAV: navType = {
  CLIENT_INFO: 'client-info',
  NIGHT: 'night',
  TOTAL: 'total',
  BIO: 'biorhythms',
  SIGN_IN: 'sign-in',
  REMIND: 'remind-password',
  ACCOUNT: 'account',
}
