type sexType = {
  [key in 'value' | 'label']: string
};

export enum ENUM_SEX {
  WOMAN = '0',
  MAN = '1'
}
export const SEX: sexType[] = [
  {
    value: ENUM_SEX.MAN,
    label: "M",
  },
  {
    value: ENUM_SEX.WOMAN,
    label: "Ж",
  },
];

type langType = {
  [key in 'value' | 'label']: string;
};

export enum ENUM_LANG {
  RU = "RU",
  UA = "UA"
}
export const LANG: langType[] = [
  {
    value: ENUM_LANG.RU,
    label: "РУ",
  },
  {
    value: ENUM_LANG.UA,
    label: "УК",
  },
];

type alphabetType = {
  [key: string]: number;
}

type numberType = {
  [key: string]: alphabetType
}
export const NUMBER_OF_LETTER: numberType = {
  RU: {
    "а": 1,
    "б": 2,
    "в": 3,
    "г": 4,
    "д": 5,
    "е": 6,
    "ж": 7,
    "з": 8,
    "и": 9,
    "і": 9,
    "ї": 9,
    "к": 1,
    "л": 2,
    "м": 3,
    "н": 4,
    "о": 5,
    "п": 6,
    "р": 7,
    "с": 8,
    "т": 9,
    "у": 1,
    "ф": 2,
    "х": 3,
    "ц": 4,
    "ч": 5,
    "ш": 6,
    "щ": 7,
    "ъ": 8,
    "ы": 9,
    "ь": 1,
    "ю": 2,
    "я": 3,
    "ё": 4,
    "э": 6,
    "є": 6,
    "й": 9,
  }
}

type EMPHATICType = {
  [key: string]: string[]
}
export const EMPHATIC: EMPHATICType = {
  RU: ['а','о','у','е','и','є','э','ю','я','ю','і','ї',"ё"]
}

export const PlanetDay: {[key: string]: string} = {
  '0': 'Со',
  '1': 'Лу',
  '2': 'Ма',
  '3': 'Ме',
  '4': 'Юп',
  '5': 'Ве',
  '6': 'Са',
  '7': 'Со',
}
