import React, {useCallback, useContext, useState} from 'react';
import {AuthContext} from "../../../../hooks/AuthContext";
import {useHttp} from "../../../../hooks/http.hook";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const ChangePassword = () => {
  const [fields, setFields] = useState({
    oldPassword: '',
    newPassword: '',
  })

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const { userEmail, onLogout } = useContext(AuthContext);
  const { request } = useHttp();

  const handleChange = useCallback((event: any) => {
    const { name, value } = event.target;
    let newValue: { [x: string]: string; };

    newValue = {
      [name]: value,
    };

    setSuccess('');
    setError('');

    setFields((prevState) => ({
      ...prevState,
      ...newValue
    }));
  }, []);

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const {oldPassword, newPassword} = fields;

    setSuccess('');
    setError('');

    const initExtended = {
      method: 'POST',
      body: {
        oldPassword,
        newPassword,
        email: userEmail,
      },
    }

    request('/api/auth/change-pass', initExtended)
      .then((res) => {
        setSuccess(res.message);
        onLogout();
      })
      .catch((err) => {
        setError(err.message);
      });

  };
  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5">
        Обновить пароль
      </Typography>
      <Box sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          name="oldPassword"
          label="Старый пароль"
          type="password"
          id="oldPassword"
          onChange={handleChange}
          value={fields.oldPassword}
          size="small"
        />

        <TextField
          margin="normal"
          required
          fullWidth
          name="newPassword"
          label="Новый пароль"
          type="password"
          id="newPassword"
          onChange={handleChange}
          value={fields.newPassword}
          size="small"
        />
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={(e) => handleSubmit(e)}
        >
          Изменить пароль
        </Button>
        {!!error && (<Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>)}
        {!!success && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {success}
          </Alert>
        )}
      </Box>
    </Box>
  );
}

export default ChangePassword;
