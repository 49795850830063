import React, {useContext} from 'react';

import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from '@mui/material/TableHead';
import TableCell from "@mui/material/TableCell";
import {ClientContext} from "../../../hooks/clientContext";
import {getEnergyName} from "../../../utils/clientInfoUtils";

const EnergyNameTable = () => {
  const mainInfo = useContext(ClientContext);

  const rows = React.useMemo(() => {
    const fullName = `${mainInfo.last_name}${mainInfo.first_name}${mainInfo.surname}`;
    return (Object.values(getEnergyName(fullName)))
  }, [mainInfo.first_name, mainInfo.last_name, mainInfo.surname]);
  return (
    <>
      <Typography variant="h6" mb={2}>Энергия имени</Typography>
      <TableContainer>
        <Table size="small" aria-label="a expression table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Чило</TableCell>
              <TableCell align="center">Колич.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rows.map((row, index) => (
                <TableRow key={row + Math.random()}>
                  <TableCell align="center" sx={{ color: 'warning.main' }}>{index + 1}</TableCell>
                  <TableCell align="center">{row}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default EnergyNameTable;
