import React, {useContext, useMemo} from 'react';
import locale from 'date-fns/locale/ru'

import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from '@mui/material/TableHead';
import TableCell from "@mui/material/TableCell";
import {ClientContext} from "../../../hooks/clientContext";
import {getSingleNumber, getSumFromStringNumber} from "../../../utils/clientInfoUtils";
import {useTheme} from "@mui/material/styles";
import {getAllMonthName, getRangeOfYears, getSumDayMonth} from "../../../utils/biorhytmsUtils";
import {ENUM_LANG} from "../../../consts/clientInfoConst";

const BiorhythmsYearsTable = () => {
  const mainInfo = useContext(ClientContext);
  const theme = useTheme();

  const months = getAllMonthName();

  const currentYear = new Date(mainInfo.dateConsultation).getFullYear();
  const birthday = new Date(mainInfo.birthday);
  const dayAndMonth = getSumDayMonth(birthday);
  const rangeYears = getRangeOfYears();

  const calculateLG = useMemo(() => {
    return rangeYears.map((year) => getSingleNumber(dayAndMonth + getSumFromStringNumber(`${year}`)))
  }, [dayAndMonth, rangeYears])

  return (
    <>
      <Typography variant="h6" mb={2}>Биоритмы</Typography>
      <TableContainer>
        <Table size="small" aria-label="a Biorhythms year table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderRight: `2px solid ${theme.palette.grey["300"]}` }}><b>Год</b></TableCell>
              <>{rangeYears.map((year) => (
                <TableCell
                  key={year}
                  sx={{ backgroundColor: year === currentYear ? theme.palette.grey["300"] : '' }}
                  align="center"
                >
                  <b>{year}</b>
                </TableCell>
              ))}</>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{
                borderRight: `2px solid ${theme.palette.grey["300"]}`,
                borderBottom: `2px solid ${theme.palette.grey["300"]}`
              }}
              >ЛГ</TableCell>
              <>{calculateLG.map((item) => (
                <TableCell
                  key={item+Math.random()}
                  sx={{
                    backgroundColor: item%2 === 0 ? theme.palette.grey["100"] : '',
                    borderBottom: `2px solid ${theme.palette.grey["300"]}`
                  }}
                  align="center"
                >
                  {item}
                </TableCell>
              ))}</>
            </TableRow>
            <>
              {
                months.map((month, ind) => (
                  <TableRow key={month} >
                    <TableCell
                      sx={{ borderRight: `2px solid ${theme.palette.grey["300"]}` }}
                      align="center"
                    >
                      {month}
                    </TableCell>
                    {
                      calculateLG.map((item) => (
                        <TableCell
                          key={item+month+Math.random()}
                          sx={{ backgroundColor: item%2 === 0 ? theme.palette.grey["100"] : '' }}
                          align="center"
                        >
                          {getSingleNumber(item + ind + 1)}
                        </TableCell>
                      ))
                    }
                  </TableRow>)
                )
              }
            </>
            {/*<TableRow>

              <>{rangeYears.map((year) => (
                <TableCell
                  key={year+'lg'}
                  sx={{ backgroundColor: year === currentYear ? theme.palette.grey["100"] : '' }}
                  align="center"
                >
                  {getSingleNumber(dayAndMonth + getSumFromStringNumber(`${year}`))}
                </TableCell>
              ))}</>
            </TableRow>*/}

          {/*//   {*/}
          {/*    rows.map((row) => (*/}
          {/*      <TableRow*/}
          {/*        key={row.year}*/}
          {/*        sx={{ backgroundColor: row.year === currentYear ? theme.palette.grey["300"] : '' }}*/}
          {/*      >*/}
          {/*        <TableCell component="th" scope="row">{row.year}</TableCell>*/}
          {/*        <TableCell align="center">{row.lg}</TableCell>*/}
          {/*        <TableCell align="center">{row.tt}</TableCell>*/}
          {/*        <TableCell align="center">{row.lag}</TableCell>*/}
          {/*      </TableRow>*/}
          {/*    ))*/}
          {/*  }*/}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default BiorhythmsYearsTable;
