import * as React from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import {useHttp} from "../../hooks/http.hook";

export interface PlaceType {
  address: string;
  latitude: string,
  longitude: string,
}

type TGetCity = {
  onGetCoordinate: (data: PlaceType | null) => void,
  selectedCity?: string
}
const GetCity = ({ onGetCoordinate, selectedCity }: TGetCity) => {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const { request } = useHttp();

  const fetch = React.useMemo(
    () =>
      debounce(
        async (input) => {
          const response = await request(`https://maps.googleapis.com/maps/api/geocode/json?address=${input}&key=AIzaSyC_J0JNwUqJ3VFn38WnAqG63O3WVWN8xvc`);

          setOptions(() => response.results?.map((result: { formatted_address: string; geometry: { location: { lat: string; lng: string; }; }; }) => {
              return {
                address: result.formatted_address,
                latitude: result.geometry.location.lat,
                longitude: result.geometry.location.lng,
              }
            })
          )
        },
        400,
      ),
    [],
  );

  React.useEffect(() => {
    if (selectedCity) {
      setValue({
        address: selectedCity,
        latitude: '',
        longitude: ''
      })
    }
  }, [selectedCity]);

  React.useEffect(() => {
    if (inputValue.length < 2) {
      return undefined;
    }

    fetch(inputValue);
  }, [fetch, inputValue]);

  return (
    <Autocomplete
      id="get-city"
      fullWidth
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.address
      }
      isOptionEqualToValue={(option, value) => option.address === value.address}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event: any, newValue: PlaceType | null) => {
        setValue(newValue);
        onGetCoordinate(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Город рождения" fullWidth size="small" />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                <Typography variant="body2" color="text.secondary">
                  {option.address}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

export default GetCity;
