import React, {useContext} from 'react';
import {ClientContext} from "../../hooks/clientContext";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import BiorhythmsYearsTable from "./components/BiorhythmsYearsTable";

const BiorhythmsPage = () => {
  const mainInfo = useContext(ClientContext)
  console.log('==========', mainInfo)
  return (
    <Grid container spacing={2} mb={3}>
      <Grid item xs={12} >
        <Paper sx={{ p: 2 }} >
          <BiorhythmsYearsTable />
        </Paper>
      </Grid>
    </Grid>

  );
}

export default BiorhythmsPage;
